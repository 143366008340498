<template>
  <v-container fluid>
    <CustomerTopBar :global="global" />
    <v-divider
      class="mt-4"
      style="border-color: rgba(17, 42, 70, 0.14) !important"
    />
    <div class="md-card md-theme-default mt-8 shadow rounded-5">
      <div class="md-card-content md-card-table">
        <div>
          <div class="d-flex justify-space-between align-center">
            <SvgIcon class="text-2xl font-semibold" text="Login Requests">
            </SvgIcon>
            <v-spacer />
            <v-select
              v-model="perPage"
              :items="[10, 15, 25, 50]"
              :menu-props="{ bottom: true, offsetY: true }"
              class="q-autocomplete shadow-0 m-r-3 mt-2"
              hide-details
              dense
              outlined
              @change="search"
              placeholder="Per Page"
              style="max-width: 100px"
            ></v-select>
            <!-- <v-autocomplete
              v-model="columns"
              :items="columnData"
              class="q-autocomplete shadow-0 m-r-3 mt-2"
              hide-details
              item-text="name"
              item-value="value"
              multiple
              dense
              outlined
              placeholder="Table Filter"
              style="max-width: 200px"
            >
              <template
                v-if="columns.length === columnData.length"
                v-slot:selection="{ index }"
              >
                <span v-if="index === 0">All Fields</span>
              </template>
              <template v-else v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span v-if="index === 1">, ...</span>
              </template>
              <template v-slot:prepend-inner>
                <SvgIcon>
                  <template v-slot:icon>
                    <FilterIcon />
                  </template>
                </SvgIcon>
              </template>
            </v-autocomplete> -->
            <v-autocomplete
              v-model="searchParams.time_intervel"
              :items="timeDuration"
              class="q-autocomplete shadow-0 m-r-3 mt-2"
              hide-details
              dense
              item-text="title"
              item-value="name"
              outlined
              placeholder="Time Period"
              style="max-width: 200px"
              @change="changeFxn"
              height="20"
            >
              <template v-slot:prepend-inner>
                <SvgIcon>
                  <template v-slot:icon>
                    <EventIcon />
                  </template>
                </SvgIcon>
              </template>
            </v-autocomplete>

            <div v-if="flag" class="d-flex align-center gap-x-4 mt-2">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                filled
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date1Formatted"
                    class="shadow-0 q-text-field"
                    dense
                    flat
                    hide-details
                    outlined
                    readonly
                    style="max-width: 180px !important"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.start_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    class="shadow-0 q-text-field"
                    dense
                    flat
                    hide-details
                    outlined
                    readonly
                    v-on="on"
                    style="max-width: 180px !important"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.end_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                class="mr-2 bg-blue text-white"
                height="40"
                elevation="0"
                @click="search"
              >
                Go
              </v-btn>
            </div>
            <!-- <v-btn
              v-if="checkExportPermission($modules.clients.customers.slug)"
              class="export-button mt-2"
              elevation="0"
              height="40"
              @click="exportData"
            >
              <SvgIcon text="Export">
                <template v-slot:icon>
                  <ExportIcon />
                </template>
              </SvgIcon>
            </v-btn> -->
          </div>

          <br /><br />
          <div class="table-responsive">
            <table class="table border-collapse">
              <thead>
                <tr class="opacity-70 tr-neon tr-rounded">
                  <th v-if="checkTableColumnVisibility('name')">
                    <div>Date</div>
                  </th>
                  <th v-if="checkTableColumnVisibility('name')">
                    <div>Name</div>
                    <!-- <div class="search_column">
                      <v-autocomplete
                        v-model="searchParams.name"
                        :items="names"
                        :loading="isLoading"
                        :search-input.sync="name"
                        class="q-autocomplete shadow-0"
                        filled
                        flat
                        hide-details
                        item-text="name"
                        item-value="name"
                        label="Name"
                        solo
                        @change="getFilterData"
                      />
                    </div> -->
                  </th>

                  <th v-if="checkTableColumnVisibility('mobile')">
                    <div>Mobile</div>
                    <!-- <div class="search_column">
                      <v-autocomplete
                        v-model="searchParams.mobile"
                        :items="phones"
                        :loading="isLoading"
                        :search-input.sync="phone"
                        class="q-autocomplete shadow-0"
                        filled
                        flat
                        hide-details
                        item-text="mobile"
                        item-value="mobile"
                        label="Mobile"
                        solo
                        @change="getFilterData"
                      ></v-autocomplete>
                    </div> -->
                  </th>
                  <th v-if="checkTableColumnVisibility('email')">
                    <div>Email</div>
                    <!-- <div class="search_column">
                      <v-autocomplete
                        v-model="searchParams.email"
                        :items="emails"
                        :loading="isLoading"
                        :search-input.sync="email"
                        class="q-autocomplete shadow-0"
                        filled
                        flat
                        hide-details
                        item-text="email"
                        item-value="email"
                        label="Email"
                        solo
                        @change="getFilterData"
                      ></v-autocomplete>
                    </div> -->
                  </th>
                  <th>
                    <div>Device</div>
                  </th>
                  <!-- <th v-if="checkTableColumnVisibility('age')">
                    <div>Age</div> -->
                  <!-- <div class="search_column">
                      <v-select
                        ref="ageRef"
                        v-model="searchParams.age"
                        :items="AgeRange"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                        label="Age"
                        solo
                        @change="ageChange"
                        style="min-width: 80px; max-width: 100px"
                      >
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <div style="width: 150px">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-text-field
                                    v-model="fromAge"
                                    append-icon="mdi-keyboard_arrow_down"
                                    class="shrink"
                                    label="Start Age"
                                  ></v-text-field>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-text-field
                                    v-model="toAge"
                                    append-icon="mdi-keyboard_arrow_down"
                                    class="shrink"
                                    label="End Age"
                                  ></v-text-field>
                                </v-list-item-subtitle>
                                <v-btn color="primary" small @click="ageOkClick"
                                  >Ok
                                </v-btn>
                              </v-list-item-content>
                            </v-list-item>
                          </div>
                        </template>
                      </v-select>
                    </div> -->
                  <!-- </th> -->
                  <!-- <th v-if="checkTableColumnVisibility('gender')">
                    <div>Gender</div> -->
                  <!-- <div class="search_column">
                      <v-autocomplete
                        v-model="searchParams.gender"
                        :items="['All', 'Male', 'Female']"
                        class="q-autocomplete shadow-0"
                        filled
                        flat
                        hide-details
                        label="Gender"
                        solo
                        @change="search"
                        style="min-width: 95px; max-width: 140px"
                      ></v-autocomplete>
                    </div> -->
                  <!-- </th> -->
                  <!-- <th v-if="checkTableColumnVisibility('nationality')">
                    <div>Nationality</div> -->
                  <!-- <div class="search_column">
                      <v-autocomplete
                        v-model="searchParams.nationality"
                        :items="['All', ...countries]"
                        class="q-autocomplete shadow-0"
                        filled
                        flat
                        hide-details
                        item-text="name"
                        item-value="name"
                        label="Nationality"
                        solo
                        @change="getFilterData"
                        style="min-width: 120px"
                      ></v-autocomplete>
                    </div> -->
                  <!-- </th> -->

                  <th
                    v-if="checkWritePermission($modules.clients.customers.slug)"
                  >
                    <div>Action</div>
                  </th>
                  <th
                    v-if="checkWritePermission($modules.clients.customers.slug)"
                  >
                    <div>History</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="data in searchDatas"
                  :key="data.request_id"
                  class="border-bottom"
                >
                  <td v-if="checkTableColumnVisibility('mobile')">
                    {{ data.requested_date | timeStamp }}
                  </td>
                  <td
                    v-if="checkTableColumnVisibility('name')"
                    style="
                      max-width: 150px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    <span
                      class="text_ellipse text-neon text-base pointer font-bold"
                      @click="showUserModel(data.id)"
                      >{{ data.first_name }} {{ data.last_name }}
                    </span>
                  </td>

                  <td v-if="checkTableColumnVisibility('mobile')">
                    {{ data.mobile || "NA" }}
                  </td>
                  <td v-if="checkTableColumnVisibility('email')">
                    {{ data.email || "NA" }}
                  </td>
                  <td>
                    {{ data.device_name || "NA" }}
                  </td>
                  <!-- <td v-if="checkTableColumnVisibility('age')">
                    {{ data.age > 0 ? data.age : "NA" }}
                  </td>
                  <td v-if="checkTableColumnVisibility('gender')">
                    {{ data.gender || "NA" }}
                  </td>
                  <td v-if="checkTableColumnVisibility('nationality')">
                    {{ data.nationality || "NA" }}
                  </td> -->
                  <td>
                    <v-btn
                      class="ma-2 text_capitalize"
                      @click="authorizeDeviceConfirmation(data.request_id)"
                      outlined
                      :color="data.auth_status == false ? 'primary' : 'red'"
                    >
                      <span class="ml-1">{{
                        data.auth_status == true ? "Unauthorize" : "Authorize"
                      }}</span>
                    </v-btn>
                  </td>
                  <td>
                    <div
                      class="pointer"
                      role="button"
                      @click="loginApprovalHistory(data.request_id)"
                    >
                      <SvgIcon class="pointer">
                        <template v-slot:icon>
                          <HistoryIcon />
                        </template>
                      </SvgIcon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination
          v-model="page"
          :length="totalPages"
          class="new-pagination"
          total-visible="7"
        ></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
          <span class=""> Result </span>
          <div style="width: 80px">
            <v-select
              v-model="perPage"
              :items="[10, 15, 25, 50]"
              :menu-props="{ bottom: true, offsetY: true }"
              class="q-autocomplete text-sm"
              flat
              hide-details
              solo
              @change="search"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loginApprovalHistoryDialog"
      width="900"
      persistent
      scrollable
      @input="$emit('close')"
    >
      <v-card>
        <v-card-text>
          <div class="d-flex justify-space-between align-center mt-2">
            <SvgIcon class="text-2xl font-semibold" text="Request History">
            </SvgIcon>
            <v-spacer />
          </div>
          <div class="table-responsive mt-4">
            <table class="table">
              <thead>
                <tr class="opacity-70 tr-neon tr-rounded">
                  <th>Timestamp</th>
                  <th>User Name</th>
                  <th>Description</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(data, index) in loginApprovalHistoryData"
                  :key="index"
                  class="text-center"
                >
                  <td>{{ data.timestamp | timeStamp }}</td>

                  <td>{{ data.first_name + " " + data.last_name }}</td>

                  <td class="md-table-cell">
                    {{ data.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <v-pagination
            class="mt-2"
            v-model="loginApprovalHistoryPage"
            :length="loginApprovalHistoryTotalPages"
          ></v-pagination>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="
              () => {
                loginApprovalHistoryDialog = false;
                closeEdit();
              }
            "
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <preview-customer-modal
      :isPermissionCheck="checkWritePermission($modules.clients.customers.slug)"
      v-bind="userModel"
      @close="userModel.userID = null"
      @updated="search()"
    />
    <confirm-model
      v-bind="confirmModel"
      @close="confirmModel.id = null"
      @confirm="confirmActions"
    ></confirm-model>
  </v-container>
</template>

<script>
import moment from "moment";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import SvgIcon from "@/components/Image/SvgIcon.vue";
//import ExportIcon from "@/assets/images/misc/export-icon.svg";
//import FilterIcon from "@/assets/images/misc/filter-search.svg";
import EventIcon from "@/assets/images/misc/calendar.svg";
import HistoryIcon from "@/assets/images/misc/history-icon.svg";
//import ActivateIcon from "@/assets/images/misc/activate.svg";
import PreviewCustomerModal from "@/views/Clients/Customer/PreviewCustomerModal.vue";
import CustomerTopBar from "@/views/Clients/CustomerTopBar.vue";

export default {
  props: {
    global: {
      type: Boolean,
      default: true,
    },
    eventId: {
      type: Number,
      default: null,
    },
  },
  components: {
    CustomerTopBar,
    SvgIcon,
    ConfirmModel,
    PreviewCustomerModal,
    //ExportIcon,
    //FilterIcon,
    EventIcon,
    HistoryIcon,
    // ActivateIcon
  },
  data() {
    return {
      loginApprovalHistoryDialog: false,
      loginApprovalHistoryPage: 1,
      menu1: false,
      menu2: false,
      flag: false,
      currentPage: 0,
      pageSize: 10,
      names: [],
      name: null,
      nationality: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      searchDatas: [],
      historyTotalPages: 1,
      loginApprovalHistoryTotalPages: 1,
      loginApprovalHistoryData: [],
      searchFlag: false,
      searchParams: {
        start_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      AgeRange: ["All", "18-25", "26-30", "31-35"],
      Age: [],
      toAge: null,
      fromAge: null,
      page: 1,
      perPage: 10,
      totalPages: 1,
      tags: [],
      tag: null,
      fieldData: {
        Name: "name",
        Mobile: "mobile",
        Email: "email",
        Age: "age",
        Gender: "gender",
        Nationality: "nationality",
      },
      userModel: { userID: null, type: "details" },
      btnShow: false,
      cardid: null,
      memberEntries: [],
      isMemberLoading: false,
      timeDuration: [
        { name: "All", title: "All" },
        { name: "day", title: "This Day" },
        { name: "week", title: "This Week" },
        { name: "month", title: "This Month" },
        { name: "year", title: "This Year" },
        { name: "custom", title: "Custom Duration" },
      ],
      columnData: [
        { name: "Name", value: "name" },
        { name: "Mobile", value: "mobile" },
        { name: "Email", value: "email" },
        { name: "Age", value: "age" },
        { name: "Gender", value: "gender" },
        { name: "Nationality", value: "nationality" },
        { name: "Type", value: "type" },
        { name: "visits", value: "visits" },
        { name: "Tags", value: "tags" },
      ],
      columns: [
        "name",
        "mobile",
        "email",
        "age",
        "gender",
        "nationality",
        "type",
        "visits",
        "tags",
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      orderBy: "id",
      orderDir: "DESC",
    };
  },

  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    // if (!this.global) {
    this.search();
    // }
  },
  created() {
    if (typeof this.$route.params.id != "undefined") {
      this.searchParams.offline_user_id = this.$route.params.id;
    }

    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
  watch: {
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    nationality(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("nationality", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
    tag(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("tag", val);
    },
    cardid(val) {
      this.searchMember(val);
    },
    page: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.loginApprovalHistoryDialog) {
            this.loginApprovalHistory();
          } else {
            this.search();
          }
        }
      },
    },
    // loginApprovalHistoryPage: {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {
    //       this.loginApprovalHistory();
    //     }
    //   },
    // },
  },
  methods: {
    closeEdit() {
      this.$emit("close");
    },
    authorizeDeviceConfirmation(id) {
      let msg =
        "This will Authorize the device to Login the App. Confirm the operation by clicking 'Yes'";
      this.confirmModel = {
        id: id,
        title: `Do you want to Authorize the device to Login the App?`,
        description: msg,
        type: "device-authorization-approval",
      };
    },
    authorizeDevice(data) {
      let formData = new FormData();
      formData.append("id", data.id ? data.id : null);

      this.$http
        .post(`venues/customers/authorize-device-login-app`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.showSuccess(`Device Authorization done Successfully.`);
            this.search();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.search();
    },
    resetCustomer() {
      delete this.searchParams.offline_user_id;
      this.$router.push(
        {
          name: "Customers",
        },
        () => {}
      );
      this.search();
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
        .get("venues/customers/filters?field=" + type + "&search=" + val)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "nationality") {
              this.countries = response.data.data;
              this.countries.unshift(val);
              this.countries.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            } else if (type == "tag") {
              this.tags = response.data.data;
              this.tags.unshift(val);
              this.tags.unshift("All");
            }
          }
        })

        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterData() {
      this.page = 1;
      this.search();
    },
    ageOkClick() {
      if (this.fromAge != null && this.toAge != null) {
        this.searchParams.from_age = this.fromAge;
        this.searchParams.to_age = this.toAge;
        let customAgeRange = this.fromAge + "-" + this.toAge;
        this.AgeRange.push(customAgeRange);
        this.searchParams.age = customAgeRange;
        this.toAge = null;
        this.fromAge = null;
        this.$refs.ageRef.blur();
        this.search();
      }
    },
    ageChange() {
      if (this.searchParams.age != "All") {
        let data = this.searchParams.age.split("-");
        this.searchParams.from_age = data[0];
        this.searchParams.to_age = data[1];
        this.search();
      } else {
        this.allAge();
      }
    },
    allAge() {
      delete this.searchParams.from_age;
      delete this.searchParams.to_age;
      delete this.searchParams.age;
      this.search();
    },
    loginApprovalHistory(requestId) {
      var params = "";
      // params = "?page=" + this.loginApprovalHistoryPage;

      this.loginApprovalHistoryData = [];
      this.showLoader("LOADING ...");
      this.$http
        .get("venues/customers/login-approval-history/" + requestId + params)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.loginApprovalHistoryData = response.data.data;
            this.loginApprovalHistoryDialog = true;
          } else {
            this.hideLoader();
          }
          this.loginApprovalHistoryTotalPages = response.data.total_pages;
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    search() {
      let url = "";
      url = "&status=all";

      if (
        this.searchParams.time_intervel != "All" &&
        this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }

      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (typeof this.searchParams.name != "undefined") {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (typeof this.searchParams.email != "undefined") {
        url += "&email=" + this.searchParams.email;
      }
      if (
        typeof this.searchParams.type != "undefined" &&
        this.searchParams.type == "m"
      ) {
        url += "&membership_type=" + this.searchParams.type;
      } else if (
        typeof this.searchParams.type != "undefined" &&
        this.searchParams.type == "n"
      ) {
        url += "&membership_type=" + this.searchParams.type;
      } else if (
        typeof this.searchParams.type != "undefined" &&
        this.searchParams.type != "all"
      ) {
        url += "&customer_type_id=" + this.searchParams.type;
      }

      if (this.searchParams.member_card_num == null) {
        delete this.searchParams.member_card_num;
      } else if (typeof this.searchParams.member_card_num != "undefined") {
        url += "&member_card_num=" + this.searchParams.member_card_num;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.mobile != "undefined") {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.gender == "All") {
        delete this.searchParams.gender;
      } else if (typeof this.searchParams.gender != "undefined") {
        url += "&gender=" + this.searchParams.gender;
      }
      if (this.searchParams.nationality == "All") {
        delete this.searchParams.nationality;
      } else if (typeof this.searchParams.nationality != "undefined") {
        url += "&nationality=" + this.searchParams.nationality;
      }
      if (typeof this.searchParams.offline_user_id != "undefined") {
        url += "&offline_user_id=" + this.searchParams.offline_user_id;
      }
      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (this.searchParams.customer_type == "All") {
        delete this.searchParams.customer_type;
      } else if (typeof this.searchParams.customer_type != "undefined") {
        url +=
          "&customer_type=" +
          (this.searchParams.customer_type == "Individual" ? "I" : "C");
      }
      if (this.searchParams.tag == "All") {
        delete this.searchParams.tag;
      } else if (typeof this.searchParams.tag != "undefined") {
        url += "&tag=" + this.searchParams.tag;
      }
      if (this.eventId > 0) {
        url += "&event_id=" + this.eventId;
      }
      this.showLoader("Loading Customer");
      this.$http
        .get(
          "venues/customers/device-authorization-requests?page=" +
            this.page +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10) +
            url
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status) {
            this.searchDatas = response.data.data;
            this.totalPages = response.data.total_pages;
            if (typeof this.searchParams.offline_user_id != "undefined") {
              this.showUserModel(this.searchParams.offline_user_id);
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },

    editCustomer(userId) {
      this.userModel.type = "edit";
      this.userModel.userID = parseInt(userId);
    },
    searchMember(val) {
      if (
        typeof this.searchParams.member_card_num != "undefined" &&
        this.searchParams.member_card_num == val
      )
        return;
      if (val == "" || val == null) return;
      if (this.isMemberLoading) return;
      this.isMemberLoading = true;
      this.$http
        .get("memberships/members/search?term=" + val)
        .then((response) => {
          if (response.status == 200) {
            this.isMemberLoading = false;
            this.memberEntries = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    exportData() {
      let url = "";
      url = "&status=all";

      if (
        this.searchParams.time_intervel != "All" &&
        this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }

      url += "&visible_fields=" + this.columns.join(",");

      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (typeof this.searchParams.name != "undefined") {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (typeof this.searchParams.email != "undefined") {
        url += "&email=" + this.searchParams.email;
      }
      if (typeof this.searchParams.type != "undefined") {
        url += "&type=" + this.searchParams.type;
      }
      if (this.searchParams.member_card_num == null) {
        delete this.searchParams.member_card_num;
      } else if (typeof this.searchParams.member_card_num != "undefined") {
        url += "&member_card_num=" + this.searchParams.member_card_num;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.mobile != "undefined") {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.gender == "All") {
        delete this.searchParams.gender;
      } else if (typeof this.searchParams.gender != "undefined") {
        url += "&gender=" + this.searchParams.gender;
      }
      if (this.searchParams.nationality == "All") {
        delete this.searchParams.nationality;
      } else if (typeof this.searchParams.nationality != "undefined") {
        url += "&nationality=" + this.searchParams.nationality;
      }
      if (typeof this.searchParams.offline_user_id != "undefined") {
        url += "&offline_user_id=" + this.searchParams.offline_user_id;
      }
      if (this.eventId > 0) {
        url += "&event_id=" + this.eventId;
      }
      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (this.searchParams.customer_type == "All") {
        delete this.searchParams.customer_type;
      } else if (typeof this.searchParams.customer_type != "undefined") {
        url +=
          "&customer_type=" +
          (this.searchParams.customer_type == "Individual" ? "I" : "C");
      }
      if (url != "") {
        url = "?" + url.substring(1);
      }
      this.$http
        .get(`venues/customers/export` + url, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadFile(response, "Customer_Export_");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },
    deactivateConfirmModal(data) {
      let type = data.status_id == 1 ? "Deactivate" : "Activate";
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${type} this customer?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
      };
    },
    confirmActions(data) {
      if (data.type == "Deactivate" || data.type == "Activate") {
        this.removeCustomer(data);
      } else if (data.type == "device-authorization-approval") {
        this.authorizeDevice(data);
      }
      this.confirmModel.id = null;
    },
    removeCustomer(data) {
      console.log("customer_id", data.id);
      this.showLoader();
      this.$http
        .delete(`venues/customers/${data.id}`)
        .then((res) => {
          if (res.status == 200) {
            this.showSuccess(`Customer ${data.type} Successfully.`);
            this.hideLoader();
            this.search();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    changeFxn() {
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.searchParams.end_date = moment().format("YYYY-MM-DD");
        this.flag = true;
      } else {
        this.flag = false;
        this.search();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.text_ellipse {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  overflow: hidden;
}
</style>
